import { createSlice } from "@reduxjs/toolkit";
import { getVehicleInwardByID, getVehilceInwardList, vehicleInwardAdd, vehicleInwardEdit, vehicleInwardDelete } from "./vehicleInwardAction";

const vehicleInwardSlice = createSlice({
    name: "vehicleInward",
    initialState: {
        error: "Sorry! No Data Found.",
        message: null,
        loading: false,
        unitsLoading: false,
        model: false,
        error_msg: null,
        rowdata: {},
        // autoFillItems: {},
        filterParamsData: {},
        listCount: 0,
        totalQuantity: 0,
        vehicleInwardlist: [],
        vehicleInwardItems: [],
        vehicleInwardParams: {
            no_of_pages: 0,
            page_size: 10,
            page: 1,
            search: "",
            currentSort: "code",
            sortOrder: "-",
        },
        listItemCount: 0,
        pageParams: {
            no_of_pages: 0,
            page_size: 10,
            page: 1,
            search: "",
            currentSort: "code",
            sortOrder: "-",
        },
        balance_quantity: 0,
        available_quantity: 0,
        editInwardItems: {}

    },
    reducers: {

        InputChangeValue: (state, action) => {
            return {
                ...state,
                rowdata: {
                    ...state.rowdata,
                    [action.payload.key]: action.payload.value,
                },
            };
        },
        InputChangeValueCompany: (state, action) => {
            return {
                ...state,
                rowdata: {
                    ...state.rowdata,
                    trucksheet: {
                        ...state.rowdata.trucksheet,
                        [action.payload.key]: action.payload.value,
                    }
                },
            };
        },
        SetVehicleInwardParams: (state, action) => {
            return {
                ...state,
                vehicleInwardParams: {
                    ...state.vehicleInwardParams,
                    ...action.payload
                },
            };
        },
        isModelVisible: (state, action) => {
            if (!action.payload) {
                return {
                    ...state,
                    model: action.payload,
                    rowdata: {},
                };
            } else {
                return {
                    ...state,
                    model: action.payload,
                };
            }
        },
        isDrawerVisible: (state, action) => {
            return {
                ...state,
                drawer: action.payload,
            };
        },
        setAutoFillItems: (state, action) => {
            return {
                ...state,
                ...action.payload,
            }
        },
        changeParams: (state, action) => {
            return {
                ...state,
                pageParams: action.payload,
            };
        },
        setInwardItemsList: (state, action) => {
            return {
                ...state,
                vehicleInwardItems: [...state.vehicleInwardItems, action.payload]
            }
        },
        setTotalQuantity: (state, action) => {
            return {
                ...state,
                totalQuantity: state.vehicleInwardItems.reduce((sum, item) => {
                    console.log(`Current sum: ${sum}, Adding item quantity: ${item.quantity}`); // Debugging line
                    return sum + item.quantity;
                  }, 0)
            }
        },
        setEditInwardItemsList: (state, action) => {
            return {
                ...state,
                vehicleInwardItems: state.vehicleInwardItems.map((e) => {
                    if (e.id == action.payload.id) {
                        return {
                            ...e,
                            ...action.payload,
                        }
                    } else {
                        return e;
                    }
                })
            }
        },
        setTruckItemsList: (state, action) => {
            return {
                ...state,
                vehicleInwardItems: action.payload
            }
        },
        removeItems: (state, action) => {
            return {
                ...state,
                vehicleInwardItems: state.vehicleInwardItems.map((e) => {
                    return e.id == action.payload
                        ? {
                            ...e,
                            dodelete: true,
                        }
                        : {
                            ...e,
                        };
                }),
            };
        },
        setEditInwardItems: (state, action) => {
            return {
                ...state,
                editInwardItems: action.payload,
            };
        },
        setBalanceQuantity: (state, action) => {
            return {
                ...state,
                balance_quantity: action.payload,
            }
        },
        setAvaliableQuantity: (state, action) => {
            return {
                ...state,
                available_quantity: action.payload
            }
        }
        
    },
    extraReducers: (builder) => {
        builder
            .addCase(getVehilceInwardList.pending, (state, action) => {
                state.status = "getVehilceInwardList loading";
                state.loading = true;
            })
            .addCase(getVehilceInwardList.fulfilled, (state, action) => {
                state.status = "getVehilceInwardList succeeded";
                state.loading = false;
                var response = action.payload.response;
                var noofpages = Math.ceil(
                    action.payload.count / state.vehicleInwardParams?.page_size
                );

                state.vehicleInwardlist = response.results;
                state.listCount = response.count;
                state.vehicleInwardParams = {
                    ...state.vehicleInwardParams,
                    no_of_pages: noofpages,
                };
                state.rowdata = {};
                state.rowdata.trucksheet_items = [];
                state.vehicleInwardItems = [];
            })
            .addCase(getVehilceInwardList.rejected, (state, action) => {
                state.status = "getVehilceInwardList failed";
                state.loading = false;
                state.error = action.error.message;
            })

            .addCase(getVehicleInwardByID.pending, (state, action) => {
                state.status = "getVehicleInwardByID loading";
                state.loading = true;
            })
            .addCase(getVehicleInwardByID.fulfilled, (state, action) => {
                state.status = "getVehicleInwardByID succeeded";
                state.loading = false
                state.rowdata = action.payload;
                // state.vehicleInwardItems = [];
                state.vehicleInwardItems = state.rowdata?.vehicleinwarditems;
                state.listItemCount = action.payload.vehicleinwarditems.length;
            }
            )
            .addCase(getVehicleInwardByID.rejected, (state, action) => {
                state.status = "getVehicleInwardByID failed";
                state.loading = false;
                state.error = action.error.message;

            })
            .addCase(vehicleInwardAdd.pending, (state, action) => {
                state.status = "vehicleInwardAdd loading";
                state.loading = true;
            })
            .addCase(vehicleInwardAdd.fulfilled, (state, action) => {
                state.status = "vehicleInwardAdd succeeded";
                state.loading = false
                state.model = action.payload.modal;
                state.message = action.payload.response;
                state.rowdata = {};
                state.vehicleInwardItems = [];
            }
            )
            .addCase(vehicleInwardAdd.rejected, (state, action) => {
                state.status = "vehicleInwardAdd failed";
                state.loading = false;
                state.error = action.error.message;
                console.log("action.error.message", action.error.message);
            })
            .addCase(vehicleInwardEdit.pending, (state, action) => {
                state.status = "vehicleInwardEdit loading";
                state.loading = true;
            })
            .addCase(vehicleInwardEdit.fulfilled, (state, action) => {
                console.log(action.payload.response)
                state.status = "vehicleInwardEdit succeeded";
                state.loading = false;
                state.active = action.payload.active;
                state.vehicleInwardItems = [];
            })
            .addCase(vehicleInwardEdit.rejected, (state, action) => {
                state.status = "vehicleInwardEdit failed";
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(vehicleInwardDelete.pending, (state, action) => {
                state.status = "vehicleInwardDelete loading";
                state.loading = true;
            })
            .addCase(vehicleInwardDelete.fulfilled, (state, action) => {
                state.status = "vehicleInwardDelete succeeded";
                state.loading = false
            }
            )
            .addCase(vehicleInwardDelete.rejected, (state, action) => {
                state.status = "vehicleInwardDelete failed";
                state.loading = false;
                state.error = action.error.message;
            })
    }

});
export const {
    InputChangeValue,
    SetVehicleInwardParams,
    isModelVisible,
    isDrawerVisible,
    setAutoFillItems,
    changeParams,
    setInwardItemsList,
    setTruckItemsList,
    removeItems,
    setEditInwardItems,
    InputChangeValueCompany,
    setEditInwardItemsList,
    setBalanceQuantity,
    setAvaliableQuantity
} = vehicleInwardSlice.actions;

export default vehicleInwardSlice.reducer;