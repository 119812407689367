import { createSlice } from "@reduxjs/toolkit";
import {getItemList , getItemByID,getItemByCompany,getItemByLocation } from "./itemAction";

const itemSlice = createSlice({
    name: "item",
    initialState: {
        error: "Sorry! No Data Found.",
        message: null,
        loading: false,        
        unitsLoading: false,
        model: false,
        error_msg: null,
        rowdata: {},
        listCount: 0,
        itemlist: [],
        itemParams: {
            no_of_pages: 0,
            page_size: 10,
            page: 1,
            search: "",
            currentSort: "code",
            sortOrder: "-",
        },
        itemByCompany: [],
        itemByLocation:[]
    },
    reducers: {
       
        InputChangeValue: (state, action) => {
            return {
                ...state,
                rowdata: {
                    ...state.rowdata,
                    [action.payload.key]: action.payload.value,
                },
            };
        },   
        SetItemParams: (state, action) => {
            return {
                ...state,
                itemParams: {
                    ...state.itemParams,
                    ...action.payload
                },
            };
        },
        isModelVisible: (state, action) => {
            if (!action.payload) {
                return {
                    ...state,
                    model: action.payload,
                    rowdata: {},
                };
            } else {
                return {
                    ...state,
                    model: action.payload,
                };
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getItemList.pending, (state, action) => {
                state.status = "getItemList loading";
                state.loading = true;
            })
            .addCase(getItemList.fulfilled, (state, action) => {
                console.log(action.payload.response)
                state.status = "getItemList succeeded";
                state.loading = false;
                var response = action.payload.response;
                var noofpages = Math.ceil(
                    action.payload.count / state.itemParams?.page_size
                );

                state.itemlist = response.results;
                state.listCount = response.count;
                state.itemParams = {
                    ...state.itemParams,
                    no_of_pages: noofpages,
                };
            })
            .addCase(getItemList.rejected, (state, action) => {
                state.status = "getItemList failed";
                state.loading = false;
                state.error = action.error.message;
            })
            
            .addCase(getItemByID.pending, (state, action) => {
                state.status = "getItemByID loading";
                state.loading = true;
            })
            .addCase(getItemByID.fulfilled, (state, action) => {
                state.status = "getItemByID succeeded";
                state.loading = false
                state.rowdata = action.payload;
            }
            )
            .addCase(getItemByID.rejected, (state, action) => {
                state.status = "getItemByID failed";
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(getItemByCompany.pending, (state, action) => {
                state.status = "getItemByCompany loading";
                state.loading = true;
            })
            .addCase(getItemByCompany.fulfilled, (state, action) => {
                state.status = "getItemByCompany succeeded";
                state.loading = false
                state.itemByCompany = action.payload.results;
            }
            )
            .addCase(getItemByCompany.rejected, (state, action) => {
                state.status = "getItemByCompany failed";
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(getItemByLocation.pending, (state, action) => {
                state.status = "getItemByLocation loading";
                state.loading = true;
            })
            .addCase(getItemByLocation.fulfilled, (state, action) => {
                state.status = "getItemByLocation succeeded";
                state.loading = false
                state.itemByLocation = action.payload.results;
            }
            )
            .addCase(getItemByLocation.rejected, (state, action) => {
                state.status = "getItemByLocation failed";
                state.loading = false;
                state.error = action.error.message;
            })
    }

});
export const {
    InputChangeValue,
    SetItemParams,
    isModelVisible
} = itemSlice.actions;

export default itemSlice.reducer;